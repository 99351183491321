import React from 'react';
import './ProductList.css'

const ProductList = () => {
    return (
        <div>
            PL
        </div>
    );
};

export default ProductList;